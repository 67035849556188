<div *ngIf="waitList">
  <div class="phone-info">
    <div class="row">
      <div class="col-auto">
        <a href="{{waitList.venue.googleMapsUrl || '#'}}" target="_blank">
          <img src="assets/icons/icon-mappin.png" class="qr-icon">
        </a>
      </div>
      <div class="col preline">
        {{waitList.venue.address.en}}
      </div>
      <div *ngIf="waitList.venue.phoneCountryCode && waitList.venue.phoneNumber" class="col-auto">
        <a href="tel:{{waitList.venue.phoneCountryCode}}{{waitList.venue.phoneNumber}}">
          <img src="assets/icons/icon-phone.png" class="qr-icon">
        </a>
      </div>
    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'QUEUED'">
    <div class="row">
      <div class="col">
        <div class="label">{{'q-position' | language}}</div>
        <mat-card *ngIf="waitList.queuePosition > 3" class="queue-card fs-2xl">
          {{ waitList.queuePosition }}
        </mat-card>
        <mat-card *ngIf="waitList.queuePosition < 4" class="queue-card fs-2xl">
          {{ 'top3' | language }}
        </mat-card>
      </div>
    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'ARRIVED' || waitList.status === 'SEATED'">
    <div class="t-center white">
      <h3>{{'thank-visiting' | language}}</h3>
    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'CONFIRMED'">
    <div class="t-center white">
      <h3>{{'look-forward' | language}} {{waitList.estimatedArrival | date: 'hh:mm a'}}</h3>

    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'LIST_CLOSED'">
    <div class="t-center white">
      <h3>{{'not-welcome' | language}}</h3>
    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'DEFERRED'">
    <div class="t-center white">
      <h3>{{'deferred-done' | language}}</h3>
    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'CANCELED'">
    <div class="t-center white">
      <h3>{{'not-in-q' | language}}</h3>
    </div>
  </div>

  <div class="info-container" *ngIf="waitList.status === 'EXPIRED'">
    <div class="t-center white">
      <h3>{{ showExpiredTips() }}</h3>
    </div>
  </div>


  <div class="info-container mb-3" *ngIf="waitList.status === 'NOTIFIED'">
    <div class="t-center">
      <p *ngIf="waitList.guestCount < 2" class="fs-large white">{{'table-ready-single' | language}}</p>
      <p *ngIf="waitList.guestCount > 1" class="fs-large white">{{'table-ready' | language}}</p>
      <p class="label mt-3 fs-normal">{{ showNotifiedTips() }}</p>
    </div>
    <div class="row mt-3">
      <div>
        <mat-card class="time-card later" (click)="confirmTable(15)">
          <div class="fs-normal">{{ showArrivalTimeTips() }}</div>
        </mat-card>
      </div>
      <div class="mt-2">
        <mat-card class="time-card cancel" (click)="cancelTable(true)">
          <div *ngIf="waitList.guestCount < 2" class="fs-normal">{{'cancel-my-seat-single' | language}}</div>
          <div *ngIf="waitList.guestCount > 1" class="fs-normal">{{'cancel-my-seat' | language}}</div>
        </mat-card>
      </div>
      <div class="mt-2" *ngIf="waitList.venue?.missedTableAllowed" >
        <mat-card class="time-card more" (click)="moreTime()">
          <div class="fs-normal">{{'need-more-time-btn' | language}}</div>
        </mat-card>
      </div>
    </div>

<!--    <div>-->
<!--      <mat-card class="card-btn" (click)="cancelTable()">{{'cancel-my-seat' | language}}</mat-card>-->
<!--    </div>-->
  </div>


  <div class="qr-container">
    <p class="label">{{'q-info' | language}}</p>
    <mat-card class="qr-card">
      <div class="row align-items-center">
        <div class="col-auto margin-left">
          <span class="mdi mdi-account-outline fs-v-large"></span>
        </div>
        <div class="col-auto"><div class="fs-large">{{waitList.guestCount}}</div></div>
        <div class="col t-right">
          <div class="fs-normal grey">{{session.code}}</div>
        </div>
        <div class="col-auto qr-box" (click)="openQrCode()">
          <img src="assets/icons/icon-qrcode.png" class="qr-icon">
<!--          <span class="mdi mdi-qrcode-edit qr-icon"></span>-->
        </div>
      </div>
    </mat-card>
  </div>

  <div class="info-container">
    <div class="small-label">{{'your-info' | language}}</div>
    <div class="reg-text">{{waitList.guest.firstName}} {{waitList.guest.lastName}}</div>
    <div class="reg-text">+{{waitList.guest.mobileCountryCode}} {{waitList.guest.mobileNumber}}</div>
    <div class="reg-text">{{waitList.guest.email}}</div>
  </div>
  <div class="info-container">
    <div class="small-label">{{'joined-at' | language}}</div>
    <div class="reg-text">{{waitList.queuedAt | date:'dd/MM/YYYY, hh:mm a'}}</div>
  </div>


  <div class="info-container" *ngIf="(waitList.status !== 'CANCELED' && waitList.status !== 'EXPIRED')">
    <div class="link" (click)="cancelTable()">{{'leave-q' | language}}</div>
  </div>

  <div *ngIf="showQr" class="qr-full" (click)="openQrCode()">
    <qrcode [qrdata]="url" [width]="256" imageSrc="assets/img/icon-q-center.png" [errorCorrectionLevel]="'M'"></qrcode>
  </div>

</div>
